<template>
  <div>

    <b-form @submit="submitForm" class="pb-3">
      <div class="row justify-content-start pt-3">
        <div class="col-sm-6 col-lg-5 text-left">
          <b-form-group
              id="input-group-2"
              label-for="emailInputField"
          >
            <label id="emailInputField-label" for="emailInputField">
              <span>Standard-E-Mail-Adresse <i id="emailInputField-label-hint" class="fas fa-question-circle"></i></span>
            </label>
            <b-form-input
                id="emailInputField"
                type="email"
                aria-describedby="emailInputField-live-feedback"
                v-model.trim="$v.form.defaultEmailAddress.$model"
                :state="validateState('defaultEmailAddress')"
            ></b-form-input>
            <b-form-invalid-feedback id="emailInputField-live-feedback">
              <span v-if="!$v.form.defaultEmailAddress.containsAtAndDotSymbol">Ihre E-Mail muss gültig sein.</span>
              <span v-if="!$v.form.defaultEmailAddress.maxLength">Ihre E-Mail darf maximal 1024 Zeichen betragen.</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-tooltip target="emailInputField-label-hint" triggers="hover" placement="topright">
          <span>
            Hier können Sie eine E-Mail-Adresse angeben, die als Standard für alle Infodienste und Newsletter
            verwendet wird.<br/><br/>
            Sie können auch mehrere E-Mail-Adressen angeben, wenn sie durch ein Komma getrennt sind. In diesem Fall
            wird eine Infomeldung an jede E-Mail-Adresse gesendet.
          </span>
        </b-tooltip>
      </div>
      <div class="row justify-content-start pt-3">
        <div class="col-sm-12 text-left">
          <table id="infoservicestable"
                 role="table"
                 aria-busy="false"
                 aria-colcount="5"
                 class="table b-table table-striped table-bordered">
            <thead role="rowgroup" class="thead-dark">
            <tr role="row" class="">
              <th role="columnheader" scope="col" aria-colindex="1" class="text-left">
                <div>Infodienst</div>
              </th>
              <th role="columnheader" scope="col" aria-colindex="2" class="text-left">
                <div>Beschreibung</div>
              </th>
              <th role="columnheader" scope="col" aria-colindex="3" class="text-center col-10-percent">
                <div>Abonniert</div>
              </th>
              <th role="columnheader" scope="col" aria-colindex="4" class="text-center col-10-percent">
                <span>Pflicht <i id="mandatory-label-hint" class="fas fa-question-circle"></i></span>
              </th>
              <th role="columnheader" scope="col" aria-colindex="5" class="text-left">
                <span>E-Mail-Adresse <i id="email-label-hint" class="fas fa-question-circle"></i></span>
              </th>
            </tr>
            </thead>
            <tbody role="rowgroup">
            <tr role="row" class="" v-for="(item, index, key) in $v.form.infoservices.$each.$iter" :key="key">
              <td aria-colindex="1" role="cell" class="">{{item.$model.infoserviceName}}</td>
              <td aria-colindex="2" role="cell" class="">{{item.$model.infoserviceDescription}}</td>
              <td aria-colindex="3" role="cell" class="col-10-percent text-center">
                <div class="mb-3 custom-control custom-checkbox">
                  <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="'cbSubscribed_'+index"
                      v-model.trim="item.$model.subscribed"
                      :disabled="item.$model.mandatory">
                  <label class="custom-control-label" :for="'cbSubscribed_'+index"></label>
                </div>
              </td>
              <td aria-colindex="4" role="cell" class="col-10-percent text-center">
                <span v-if="item.$model.mandatory">
                  <i class="fas fa-check-circle"></i>
                </span>
              </td>
              <td aria-colindex="5" role="cell" class="">
                <input
                    :class="{ 'is-invalid': item.emailAddress.$model && item.emailAddress.$invalid, 'is-valid': item.emailAddress.$model && !item.$invalid.emailAddress }"
                    class="form-control"
                    :id="'emailInput_'+index"
                    :aria-describedby="'listEmailInputField-live-feedback_'+index"
                    v-model.trim="item.$model.emailAddress">
                <div class="invalid-feedback" :id="'listEmailInputField-live-feedback_'+index">
                  <span v-if="!item.emailAddress.maxLength">Ihre E-Mail darf maximal 1024 Zeichen betragen.</span>
                  <span v-if="!item.emailAddress.containsAtAndDotSymbol">Ihre E-Mail muss gültig sein.</span>
                </div>
              </td>

            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="row justify-content-start pt-3">
        <div class="col-sm-12 col-lg-11 text-left">
          <b-form-group
              id="input-group-2"
              label-for="newsletterAboInputField"
          >
            <b-form-checkbox
                id="newsletterAboInputField"
                name="newsletterAboInputField"
                v-model.trim="$v.form.newsletterPermission.$model"
            >
              Allgemeine Zustimmung zum Newsletter-Versand
            </b-form-checkbox>
            <p class="pt-3">Diese Zustimmung gilt zusätzlich zum Einzelabonnement der als Newsletter geltenden Infodienste.</p>
          </b-form-group>
        </div>
      </div>
      <div class="row justify-content-end pt-sm-4">
        <div class="text-right mr-3">
          <b-button
              id="newsletter-submit"
              type="submit"
              variant="default"
              :disabled="this.isWaiting"
          >
            Speichern
          </b-button>
        </div>
      </div>
      <b-tooltip target="mandatory-label-hint" triggers="hover" placement="topright">
        <span>
          Pflicht-Infodienste sind automatisch abonniert und müssen eine gültige E-Mail-Adresse hinterlegt haben.
        </span>
      </b-tooltip>
      <b-tooltip target="email-label-hint" triggers="hover" placement="topright">
        <span>
          Hier können Sie eine vom Standard abweichende E-Mail-Adresse angeben.<br/><br/>
          Sie können auch mehrere E-Mail-Adressen angeben, wenn sie durch ein Komma getrennt sind. In diesem Fall
          wird eine Infomeldung an jede E-Mail-Adresse gesendet.
        </span>
      </b-tooltip>
    </b-form>
    
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {maxLength} from "vuelidate/lib/validators";
import {mapGetters} from "vuex";

const containsAtAndDotSymbol = (value) => {
  if ( !value )  {
    return true;
  }
  return value.includes("@") && value.includes(".")
}

export default {
  name: "InfoservicesTable",
  computed: {
    ...mapGetters('user', ['isWaiting','isSaving','status']),
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ],
    }
  },
  data() {
    return {
      error: null,
      hintHtml: null,
      rawData: {},
      form: {
        defaultEmailAddress: null,
        newsletterPermission: false,
        infoservices: []
      },
      validationResults: [],
      listFields: [
        { key: 'infoserviceName', label: 'Infodienst', thClass: ["text-left"] },
        { key: 'infoserviceType', label: 'Infodienst-Art', thClass: ["text-left"] },
        { key: 'infoserviceDescription', label: 'Beschreibung', thClass: ["text-left"] },
        { key: 'infoserviceName', label: 'Infodienst', thClass: ["text-left"] },
        { key: 'subscribed', label: 'Abonniert', thClass: ["text-center", "col-10-percent"], tdClass: ["col-10-percent", "text-center"] },
        { key: 'mandatory', label: 'Pflicht', thClass: ["text-center", "col-10-percent"], tdClass: ["col-10-percent", "text-center"] },
        { key: 'emailAddress', label: 'E-Mail-Adresse', thClass: ["text-left"] },
      ],
    }
  },
  validations() {
    return {
      form: {
        defaultEmailAddress: {
          maxLength: maxLength(1024),
          containsAtAndDotSymbol,
        },
        newsletterPermission: {},
        infoservices: {
          $each: {
            emailAddress: {
              maxLength: maxLength(1024),
              containsAtAndDotSymbol,
            }
          }
        }
      },
    }},
  methods: {
    validateState(fieldName) {
      const { $dirty, $error } = this.$v.form[fieldName];
      return $dirty ? !$error : null;
    },
    submitForm(event) {
      event.preventDefault();
      this.$v.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.submitNewsletterData();
    },
    getInfoserviceData() {
      this.rawData = this.$store.getters["user/infoserviceData"];
      console.log( JSON.stringify( this.rawData ) );
      this.form.defaultEmailAddress = this.rawData.defaultEmailAddress;
      this.form.newsletterPermission = this.rawData.newsletterPermission && this.rawData.newsletterPermission === true ? this.rawData.newsletterPermission : false;
      this.form.infoservices = this.rawData.infoservices;
      return this.rawData;
    },
    submitNewsletterData() {
      this.$store.dispatch('user/storeInfoservices',this.form).then(response => {
        let debugRun = undefined;
        if ( debugRun ) console.log( response )
        this.getInfoserviceData();
      }, error => {
        console.log( error )
      })
    }
  },
  created: function() {
    this.$store.dispatch("user/loadInfoservices").then(response => {
      let debugRun = undefined;
      if ( debugRun ) console.log( response )
      this.getInfoserviceData();
    }, error => {
      console.log( error )
    })
  },
  mixins: [validationMixin],
}
</script>

<style>

</style>
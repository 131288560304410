<template>
  <div>
    <section>
      <Hero>
        <div>
          <div class="container">
            <HeadText :titletext="'Newsletterverwaltung'" :subtext="''"></HeadText>
          </div>
          <div class="container bg-light pt-3 px-3" v-if="!hasToken()">
            <b-row>
              <b-col md="12" class="text-left">
                <p>
                  Sie sind nicht angemeldet. Bitte melden Sie sich <router-link to="/login">hier</router-link> an.
                </p>
              </b-col>
            </b-row>
          </div>
          <div class="container bg-light pt-3 px-3" v-else-if="this.isWaiting">
            <b-row>
              <b-col md="12" class="text-left">
                <div class="text-center my-2 mr-3">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Die Daten werden geladen...</strong>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="container bg-light pt-3 px-3" v-else-if="this.isSaving">
            <b-row>
              <b-col md="12" class="text-left">
                <div class="text-center my-2 mr-3">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Die Daten werden gespeichert...</strong>
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="container bg-light pt-3 px-3" v-else>
            <b-row>
              <b-col md="6" class="text-left">
                <p>
                  Hier können Sie Ihre Newslettereinstellungen ändern. 
                </p>
              </b-col>
              <b-col md="6" class="text-left">
                <FdbContact></FdbContact>
              </b-col>
            </b-row>

            <InfoservicesTableMobile v-if="mobileViewport"></InfoservicesTableMobile>
            <InfoservicesTable v-else></InfoservicesTable>

          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import FdbContact from "@/components/FdbContact";
import InfoservicesTableMobile from "@/components/infoservices/InfoservicesTableMobile";
import InfoservicesTable from "@/components/infoservices/InfoservicesTable";

export default {
  name: "Infoservices",
  components: {Hero, HeadText, FdbContact, InfoservicesTable, InfoservicesTableMobile},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
    }
  },
  computed: {
    mobileViewport() {
      return window.innerWidth < this.$store.getters.getMobileBreakpoint;
    }
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    }
  }
}
</script>

<style scoped>

</style>